// todo: remove lodash on client
import get from 'lodash/get';

const extendText = (
  textWithKeys: unknown,
  data: Record<string, string>,
  modifiers: Record<string, (_: string) => string> = {},
) => {
  if (typeof textWithKeys !== 'string') {
    return textWithKeys;
  }

  return textWithKeys.replace(/\$\(([\w.:]+)\)/g, (_, matchedKey: string) => {
    const [key, ...actionKeys] = matchedKey.split(':');
    const dataValue = get(data, key, '');

    return actionKeys.reduce((value, actionKey) => {
      const modifier = modifiers[actionKey];
      return typeof modifier === 'function' ? modifier(value) : value;
    }, dataValue);
  });
};

export default extendText;
