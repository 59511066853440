import { PROMPT_LOGIN, PROMPT_RECOVER_ACCOUNT } from '@glass/common/modules/auth/actionCodes';
import { ROCKET_AUTH_LINK_EMAIL } from '@glass/common/modules/email/emailIds';
import {
  PROFILE_CHANGE_PASSWORD_PATHNAME,
  PROFILE_PATHNAME,
  ROCKET_LINK_PATHNAME,
} from '@glass/common/modules/pages/paths';
import { FAIL_QUERY, NEXT_QUERY } from '@glass/common/modules/pages/queryParams';

import { SIGNIN_ACTION } from '@glass/shared/modules/auth/authActionTypes';
import sendCommunicationAction from '@glass/shared/modules/auth/sendCommunicationAction';
// eslint-disable-next-line import/no-cycle
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import { MESSAGE_AUTH_VIEW, PASSWORD_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';
import handleErrorActions from '@glass/shared/modules/errors/handleErrorActions';

const handlePromptRecoverAction =
  (error, { next, ...options }, { email, acceptedTermsAt }) =>
  (dispatch) => {
    return dispatch(
      sendCommunicationAction({
        email,
        emailId: ROCKET_AUTH_LINK_EMAIL,
        options: {
          ctaPath: PROFILE_CHANGE_PASSWORD_PATHNAME,
          ctaQuery: { [NEXT_QUERY]: next || PROFILE_PATHNAME, [FAIL_QUERY]: ROCKET_LINK_PATHNAME },
        },
      }),
    ).then(() =>
      dispatch(
        promptAuthAction({
          email,
          next,
          ...options,
          view: MESSAGE_AUTH_VIEW,
          title: 'Check your email',
          message: 'Check your email for a Rocket Link to sign in',
          acceptedTermsAt,
        }),
      ),
    );
  };

const handleCreatePromptLoginAction =
  (
    error,
    { acceptedTermsAt: acceptedTermsAtOptions },
    { email, acceptedTermsAt: acceptedTermsAtForm },
  ) =>
  (dispatch) =>
    dispatch(
      promptAuthAction({
        email,
        actionType: SIGNIN_ACTION,
        view: PASSWORD_AUTH_VIEW,
        message: 'Enter your password to sign in',
        acceptedTermsAt: acceptedTermsAtOptions || acceptedTermsAtForm,
      }),
    );

const handleCreateUserErrorAction =
  (error, ...args) =>
  (dispatch) => {
    return dispatch(
      handleErrorActions(
        error,
        {
          [PROMPT_LOGIN]: handleCreatePromptLoginAction,
          [PROMPT_RECOVER_ACCOUNT]: handlePromptRecoverAction,
        },
        ...args,
      ),
    );
  };

export default handleCreateUserErrorAction;
